export default {
    Kurdish: {
        home_page: "پەڕەی سەرەکی",
        number_of_employees: "ژمارەی کارمەندەکان",
        number_of_engineers: "ژمارەی سەرپەرشتیارەکان",
        number_of_staffs: "ژمارەی ستافەکان",
        number_of_expired_passport: "ژمارەی پاسپۆرتە بەسەرچووەکان",
        foods_number: "ژمارەی خواردنەکان",
        jobs: "کارەکان",
        staffs: "ستافەکان",
        employee_staff_history: "مێژووی گۆڕینی ستافی کارمەندان",
        employee_active_history: "مێژووی ئەکتیڤ بوونی کارمەندان",
        add_staff_list_for_rest_days: "دروست کردنی لیستی ستافەکان بۆ ڕۆژانی پشوو",
        id: "کۆد",
        list_id: "کۆدی لیست",
        staff: "ستاف",
        user: "بەکارهێنەر",
        location: "شوێن",
        note: "تێبینی",
        detail: "ناوەڕۆک",
        job_title: "كـار",
        add: "زیادکردن",
        save_changes: "گۆڕانی زانیاری",
        actions: "کردارەکان",
        supervisor: "سەرپەرشتیار",
        staff_name: "ناوی ستاف",
        employee: "کارمەند",
        old_staff: "ستافی کۆن",
        new_staff: "ستافی تازە",
        change_date: "بەرواری گۆڕین",
        delete: "سڕینەوە",
        change_type: "جۆری گۆڕانکاری",
        create_list_for_all_staffs: "دروست کردنی لیست بۆ هەموو ستافەکان",
        waiting: "چاوەڕوانبە",
        list_detail: "ناوەڕۆکی لیست",
        status: "دۆخ",
        present: "ئامادەبوو",
        absent: "نەهاتوو",
        off: "پشوو",
        search: "گـەڕان",
        total: "کۆی گشتی",
        expired_passports: "پاسپۆرتە بەسەرچووەکان",
        expire_date: "بەرواری بەسەرچوون",
        overtime: "کاتی زیادە",
        worked_hours: "کاتی ئیشکردن",
        transfered_employees: "Transfered Employees",
        year: "Year",
        month: "Month",
        from_staff: "From Staff",
        work_date: "Work Date"
    },
    English: {
        home_page: "Home Page",
        number_of_employees: "Number Of Employees",
        number_of_engineers: "Number Of Supervisors",
        number_of_staffs: "Number Of Staffs",
        number_of_expired_passport: "Number Of Expired Passports",
        foods_number: "Food numbers",
        jobs: "Jobs",
        staffs: "Staffs",
        employee_staff_history: "Employee staff history",
        employee_active_history: "Employee active history",
        add_staff_list_for_rest_days: "Add staff lists for rest days",
        id: "ID",
        list_id: "List ID",
        staff: "Staff",
        user: "User",
        location: "Location",
        detail: "Detail",
        note: "Note",
        job_title: "Job Title",
        add: "Add",
        save_changes: "Save Changes",
        actions: "Actions",
        supervisor: "Supervisor",
        staff_name: "Staff name",
        employee: "Employee",
        old_staff: "Old Staff",
        new_staff: "New Staff",
        change_date: "Change Date",
        delete: "Delete",
        change_type: "Change Type",
        create_list_for_all_staffs: "Create List for all staffs",
        waiting: "Waiting",
        list_detail: "List Detail",
        status: "Status",
        present: "Present",
        absent: "Absent",
        off: "Off",
        search: "Search",
        total: "Total",
        expired_passports: "Expired passports",
        expire_date: "Expire date",
        overtime: "Overtime",
        worked_hours: "Worked hours",
        transfered_employees: "Transfered Employees",
        year: "Year",
        month: "Month",
        from_staff: "From Staff",
        work_date: "Work Date"
    },
    Arabic: {
        home_page: "الصفحة الرئيسية",
        number_of_employees: "عدد الموظفين",
        number_of_engineers: "عدد المشرفين",
        number_of_staffs: "عدد العاملین",
        number_of_expired_passport: "عدد جوازات السفر منتهية الصلاحية",
        foods_number: "عدد الوجبات الطعام",
        jobs: "يعمل",
        staffs: "العاملين",
        employee_staff_history: "تاريخ تغيير الموظفين",
        employee_active_history: "تاريخ نشاط الموظف",
        add_staff_list_for_rest_days: "إنشاء قائمة الموظفين للإجازات",
        id: "رقـم",
        list_id: "رقم القائمة",
        staff: "العاملين",
        user: "المستعمل",
        location: "موقع",
        note: "ملاحظة",
        detail: "المحتوى",
        job_title: "عـمل",
        add: "اضافة",
        save_changes: "تغيير المعلومات",
        actions: "أجراءات",
        supervisor: "مشرف",
        staff_name: "اسم العاملین",
        employee: "موظف",
        old_staff: "العاملین القدامى",
        new_staff: "العاملین جديد",
        change_date: "تاريخ التغيير",
        delete: "حذف",
        change_type: "نوع التغيير",
        create_list_for_all_staffs: "إنشاء قائمة لجميع العاملین",
        waiting: "انتظر",
        list_detail: "محتوى القائمة",
        status: "حالة",
        present: "كان حاضرا",
        absent: "غیاب",
        off: "يوم الاجازة",
        search: "بحث",
        total: "مجموع",
        expired_passports: "جوازات السفر منتهية الصلاحية",
        expire_date: "تاريخ إنتهاء الصلاحية",
        overtime: "وقت إضافي",
        worked_hours: "وقت العمل",
        transfered_employees: "Transfered Employees",
        year: "Year",
        month: "Month",
        from_staff: "From Staff",
        work_date: "Work Date"
    }
}